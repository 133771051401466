<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
      class="mt-3"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', currentTabIndex === 0)" /> Pendings
        </template>
        <ApprovalPending
          ref="saPending"
          :tab-index="currentTabIndex"
          @show="showApproval"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-history', currentTabIndex === 2)" /> Histories
        </template>
        <ApprovalHistory
          ref="saHistory"
          :tab-index="currentTabIndex"
          @show="showApproval"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      id="modalApproval"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      content-class="full-height"
      modal-class="full-height"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-stack-overflow-line h4" />
              <b class="is-tab-active">Products</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="PO Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.purchase_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Requested By"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.destination_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Group"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.group_name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Remarks"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.remarks || 'no remarks'"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="state.approval"
                  cols="12"
                  class="px-1 px-md-2 d-flex justify-content-end align-items-end"
                >
                  <div class="mb-2">
                    <b-overlay
                      :show="fetching.stocks"
                      rounded="sm"
                      opacity="0.6"
                      spinner-small
                      spinner-variant="warning"
                    >
                      <b-button
                        size="sm"
                        variant="outline-success"
                        class="text-nowrap"
                        @click.prevent="getStocks"
                      >
                        Query SAP Stocks
                      </b-button>
                    </b-overlay>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="px-0 px-md-1"
            >
              <ValidationObserver
                ref="formProducts"
              >
                <b-table
                  ref="tableProducts"
                  small
                  hover
                  striped
                  bordered
                  responsive
                  show-empty
                  class="mb-0"
                  :items="stockRequest.details"
                  :busy="tableProducts.busy"
                  :filter="tableProducts.filter"
                  :fields="state.approval ? tableProducts.fields : tableProducts.histories"
                  :stacked="tableProducts.stacked"
                  :filter-included-fields="tableProducts.filterOn"
                >
                  <template #cell(index)="{ index }">
                    <div
                      class="text-md-nowrap"
                      v-text="tableRowNumber(tableProducts, index)"
                    />
                  </template>

                  <template #cell()="{ value }">
                    <div
                      class="text-md-nowrap d-flex align-items-center min-h-35px px-1"
                      v-text="value"
                    />
                  </template>

                  <template #cell(product_name)="{ value }">
                    <div class="text-md-nowrap d-flex align-items-center min-h-35px px-1">
                      <strong
                        class="font-weight-bolder"
                        v-text="value"
                      />
                    </div>
                  </template>

                  <template #cell(onhand_quantity)="{ value }">
                    <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                      <strong
                        :class="['font-weight-bolder', {
                          'text-danger': Number(value) === 0
                        }]"
                        v-text="value"
                      />
                    </div>
                  </template>

                  <template #cell(quantity)="{ value }">
                    <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                      <strong
                        class="font-weight-bolder"
                        v-text="value"
                      />
                    </div>
                  </template>

                  <template #cell(approved_quantity)="{ item, index }">
                    <div
                      v-if="state.approval"
                      class="text-md-nowrap"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        :vid="`details.${index}.quantity`"
                        :rules="{
                          required: true,
                          min_value: 0,
                          max_value: item.quantity
                        }"
                        name="quantity"
                      >
                        <b-form-group
                          :label-for="`fApprovedQuantity-${index}`"
                          class="mb-0"
                        >
                          <b-input
                            :id="`fApprovedQuantity-${index}`"
                            v-model="item.approved_quantity"
                            :disabled="state.busy"
                            :state="(errors.length > 0 ? false : null)"
                            :max="item.quantity"
                            placeholder="enter approval quantity here"
                            class="text-center"
                            type="number"
                            step="1"
                            min="0"
                          />
                          <div
                            v-if="(errors.length > 0)"
                            class="invalid-feedback"
                            v-text="errors[0]"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div
                      v-else
                      class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px"
                    >
                      <strong
                        :class="['font-weight-bolder', {
                          'text-danger': Number(item.approved_quantity) <= 0,
                          'text-warning': Number(item.quantity) !== Number(item.approved_quantity),
                          'text-success': Number(item.quantity) === Number(item.approved_quantity)
                        }]"
                        v-text="item.approved_quantity"
                      />
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center py-5">
                      <b-icon
                        icon="stopwatch"
                        font-scale="5"
                        animation="cylon"
                      />
                      <p class="h3 py-2">
                        <strong>Loading . . .</strong>
                      </p>
                    </div>
                  </template>

                </b-table>
              </ValidationObserver>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-todo-line h4" />
              <b class="is-tab-active">Delivery Details</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label-for="fCustomerDirect"
                    class="mb-1"
                  >
                    <b-form-checkbox
                      id="fCustomerDirect"
                      v-model="stockRequest.delivery_details.customer_direct"
                      name="customer_direct"
                      unchecked-value="0"
                      value="1"
                      disabled
                      switch
                    >
                      <strong>Direct Deliver to Customer?</strong>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvCustomerMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerMobile"
                      v-model="stockRequest.delivery_details.mobile_number"
                      placeholder="no customer mobile"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Name"
                    label-for="fvCustomerName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerName"
                      v-model="stockRequest.delivery_details.customer"
                      placeholder="no customer name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Address"
                    label-for="fvCustomerAddress"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-textarea
                      id="fvCustomerAddress"
                      v-model="stockRequest.delivery_details.address"
                      placeholder="no customer address"
                      maxlength="50"
                      max-rows="6"
                      disabled
                      rows="4"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          v-if="!state.approval"
          class="p-2"
        >
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-calendar-line h4" />
              <b class="is-tab-active ml-1">Delivery Schedule</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Delivery Date"
                    label-for="fvScheduleDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleDate"
                      v-model="stockRequest.schedule_details.date"
                      type="date"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="5"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Driver"
                    label-for="fvScheduleDriver"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleDriver"
                      v-model="stockRequest.schedule_details.driver_name"
                      type="text"
                      maxlength="50"
                      placeholder="no driver"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvScheduleMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleMobile"
                      v-model="stockRequest.schedule_details.mobile_number"
                      type="text"
                      maxlength="50"
                      placeholder="no mobile number"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                />

                <b-col
                  cols="12"
                  md="8"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Helper"
                    label-for="fvScheduleHelper"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleHelper"
                      v-model="stockRequest.schedule_details.helper"
                      type="text"
                      maxlength="50"
                      placeholder="no helper"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Vehicle Plate"
                    label-for="fvScheduleVehiclePlate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleVehiclePlate"
                      v-model="stockRequest.schedule_details.vehicle_plate"
                      type="text"
                      maxlength="25"
                      placeholder="no vehicle plate"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="8"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Vehicle Name"
                    label-for="fvScheduleVehicleName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleVehicleName"
                      v-model="stockRequest.schedule_details.vehicle_name"
                      type="text"
                      maxlength="50"
                      placeholder="no helper"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-history-line h4" />
              <b class="is-tab-active ml-1">Tracking</b>
            </div>
          </template>

          <Timeline
            :stock-request="stockRequest"
          >
            <template #approvals="request">
              <Signatory
                :stock-request="request"
                :approver="requesterSignatory"
              />
              <Signatory
                v-for="(approver, key) in request.approvals"
                :key="key"
                :stock-request="request"
                :approver="approver"
              />
            </template>
          </Timeline>
        </b-tab>

      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="flex-grow-1 d-flex justify-content-between mb-0 pb-0">
          <div>
            <b-button
              v-if="state.approval"
              variant="outline-danger"
              :disabled="state.busy"
              @click.prevent="$event => validateApproval($event, 'Disapproved')"
            >
              Disapprove
            </b-button>
          </div>
          <div>
            <b-button
              v-if="state.approval"
              variant="primary"
              class="mr-2"
              :disabled="state.busy"
              @click.prevent="$event => validateApproval($event, 'Approved')"
            >
              Approve
            </b-button>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click.prevent="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
// import FUSE from 'fuse.js'
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SSharedList } from '@/services'

import ApprovalPending from '@/views/user/approvals/Pending'
import ApprovalHistory from '@/views/user/approvals/History'
import Timeline from '@/components/custom/timeline/Base'
import Signatory from '@/components/custom/timeline/Signatory'

export default {

  name: 'UserApprovalBase',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Approvals'
  }),

  components: {
    ApprovalPending,
    ApprovalHistory,
    Timeline,
    Signatory
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  data () {
    return {
      currentTabIndex: this.getCurrentTabIndexByURL(),
      state: {
        busy: false,
        approval: false,
        validStocks: false
      },
      fetching: {
        stocks: false
      },
      stockRequest: {
        id: null,
        stage: 0,
        references: {
          document_number: null,
          transfer_number: null,
          sap_doc_entry: null,
          purchase_number: null
        },
        headers: {
          destination: null,
          destination_name: null,
          destination_code: null,
          origin: null,
          origin_name: null,
          origin_code: null,
          group: null,
          group_name: null,
          group_code: null
        },
        delivery_details: {
          customer_direct: 0,
          customer: null,
          address: null,
          mobile_number: null
        },
        schedule_details: {
          date: null,
          vehicle: null,
          vehicle_plate: null,
          vehicle_name: null,
          driver: null,
          driver_name: null,
          mobile_number: null,
          helper: null
        },
        details: [],
        approvals: [],
        remarks: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      tableProducts: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'product_name', class: 'min-w-200px' },
          { mobile: 2, key: 'onhand_quantity', label: 'SAP Stocks', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 3, key: 'quantity', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 4, key: 'approved_quantity', label: 'Approved', class: ['text-center', { 'col-2': !this.isMobile() }] }
        ].sort(this.scaleOnMobile),
        histories: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'product_name', class: 'min-w-200px' },
          { mobile: 2, key: 'quantity', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 3, key: 'approved_quantity', label: 'Approved', class: ['text-center', { 'col-2': !this.isMobile() }] }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    getCurrentTabIndexByURL () {
      switch (this.$route.params.approver) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    },

    getStocks () {
      this.fetching.stocks = this.state.busy = true

      return SSharedList.getStocks({
        product_codes: this.stockRequest.details.map(
          detail => detail.product_code
        ),
        warehouse_code: this.stockRequest.headers.origin_code
      }).then(({ data }) => {
        this.stockRequest.details = this.stockRequest.details.map(
          detail => {
            const product = QUERY.find(data, {
              product_code: detail.product_code
            })
            const quantity = parseInt(product.available_stock)

            return Object.assign(detail, {
              onhand_quantity: !isNaN(quantity) ? quantity : 0
            })
          }
        )
      }).catch(() => {
        this.stockRequest.details = this.stockRequest.details.map(
          detail => Object.assign(detail, {
            onhand_quantity: 0
          })
        )
      }).finally(() => {
        this.fetching.stocks = this.state.busy = false
      })
    },

    showApproval (stockRequest, useApproval) {
      this.state.approval = useApproval

      const { id, approvable } = stockRequest
      const { delivery_details, schedule_details, transfer_details } = approvable

      this.stockRequest = Object.assign(this.stockRequest, {
        id: id,
        stage: approvable.stage,
        references: {
          document_number: approvable.document_number,
          transfer_number: approvable.transfer_number,
          sap_doc_entry: approvable.sap_doc_entry,
          purchase_number: approvable.purchase_number
        },
        headers: {
          destination: approvable.destination_id,
          destination_name: approvable.destination_name,
          destination_code: approvable.destination_code,
          origin: approvable.origin_id,
          origin_code: approvable.origin_code,
          origin_name: approvable.origin_name,
          group: approvable.group_id,
          group_code: approvable.group_code,
          group_name: approvable.group_name
        },
        delivery_details: {
          customer_direct: Number(approvable.customer_direct),
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        schedule_details: {
          date: schedule_details?.date || null,
          vehicle: schedule_details?.vehicle || null,
          vehicle_plate: schedule_details?.vehicle_plate || null,
          vehicle_name: schedule_details?.vehicle_name || null,
          driver: schedule_details?.driver || null,
          driver_name: schedule_details?.driver_name || null,
          mobile_number: schedule_details?.mobile_number || null,
          helper: schedule_details?.helper || null
        },
        details: (
          useApproval ? transfer_details.map(
            product => Object.assign(product, {
              onhand_quantity: 0,
              approved_quantity: product.quantity
            })
          ) : approvable.transfer_details
        ),
        approvals: approvable.approvals,
        remarks: approvable.remarks,
        created_at: approvable.created_at
      })

      this.requesterSignatory = Object.assign(this.requesterSignatory, {
        approver_name: approvable.destination_name,
        status_at: approvable.created_at
      })

      this.$bvModal.show('modalApproval')
    },

    validateApproval ({ target }, status = 'Approved') {
      this.$refs.formProducts.validate().then(
        validProducts => {
          if (!validProducts) {
            return this.swalInvalid()
          }

          const allInputsAreZero = (QUERY.filter(
            this.$refs.tableProducts.localItems,
            product => (Number(product.approved_quantity) <= 0)
          ).length) === (this.$refs.tableProducts.localItems.length)

          if (allInputsAreZero) {
            return this.swalInvalid('<h6>All your inputs are filled with zero value, Disapprove it instead.</h6>', 'Notice')
          }

          this.swalConfirmWithRemarks({
            html: `${target.innerText} Purchase Order?`,
            remarksRequired: status === 'Disapproved',
            customClass: {
              confirmButton: (
                status === 'Disapproved' ? (
                  'btn btn-sm btn-danger'
                ) : 'btn btn-sm btn-success'
              )
            },
            preConfirm: remarks => {
              return this.$refs.saPending.update({
                id: this.stockRequest.id,
                details: this.stockRequest.details.map(
                  ({ id, quantity, approved_quantity }) => ({
                    id,
                    quantity,
                    approved_quantity
                  })
                ),
                remarks,
                status
              })
            },
            confirmButtonText: target.innerText
          })
        }
      )
    }

  }
}
</script>
